import { graphql, navigate, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import SuccessPage from "../../components/global/SuccessPage";
import theme from "../../theme";
const RegistrationSuccess = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "green-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        publicURL
      }
      certifiedBadge: file(relativePath: { eq: "certified-badge.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 135, quality: 100)
        }
      }
    }
  `);

  useEffect(() => {
    if (!location.state || !location.state.registrationCompleted) {
      navigate("/daily-reporting");
      return undefined;
    }
  });

  return (
    <SuccessPage
      pageTitle="Report submitted successfully"
      image={data.bgImage}
      badge={data.certifiedBadge.childImageSharp.gatsbyImageData}
      message="Report successfully submitted"
      subMessage={[
        "Click ",
        <a
          key="link"
          href="/daily-reporting"
          style={{
            textDecoration: "none",
            color: theme.palette.secondary.main,
            fontWeight: 500,
          }}
        >
          here
        </a>,
        " if you want to fill another report",
      ]}
    />
  );
};

RegistrationSuccess.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      registrationCompleted: PropTypes.bool,
    }),
  }).isRequired,
};

export default RegistrationSuccess;
